import request from '@/utils/request';

/** 门店的棚位概况 */
export function shedOverview(data) {
  return request({
    url: 'supplier-web-api/admin/store/shed/shedOverview',
    method: 'POST',
    data,
  });
}
/** 查询ERP部门 */
export function searchdpt(data) {
  return request({
    url: 'product-web-api/api/erp/searchdpt',
    method: 'POST',
    data,
  });
}
/** 查询ERP员工 */
export function searchemp(data) {
  return request({
    url: 'product-web-api/api/erp/searchemp',
    method: 'POST',
    data,
  });
}
/** 查询ERP棚位 */
export function searchSeat(data) {
  return request({
    url: 'product-web-api/api/erp/searchSeat',
    method: 'POST',
    data,
  });
}
