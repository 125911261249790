<template>
  <el-dialog class="product-detail-container" :title="popupsTitle" :visible="showState" width="886px" top="3vh" @close="hidePopups()">
    <div ref="productDetail" v-loading="boxLoading">
      <div class="flex product-info">
        <el-image class="product-img" :src="detailsInfo.coverPictureUrl" alt="" />
        <div class="product-r">
          <div class="product-title">{{ detailsInfo.productName }}</div>
          <div class="product-price">
            <span class="current-price">¥{{ detailsInfo.salePrice }}</span>
            <span v-if="filterMarketPrice(detailsInfo.marketPrice)" class="old-price">
              ¥{{ detailsInfo.marketPrice }}
              <span class="hr"></span>
            </span>
          </div>
          <div class="product-prompt">{{ detailsInfo.productInfo.propertyNames }}</div>
        </div>
      </div>
      <div class="flex city">
        <div class="city-label">适用城市：</div>
        <div class="city-content">{{ detailsInfo.productCityNames }}</div>
      </div>
      <el-tabs v-model="activeName" :before-leave="handleMove">
        <el-tab-pane label="产品说明" name="0">
          <el-scrollbar ref="elScrollbar" style="height: 500px">
            <div class="title">
              <span class="title-mark"></span>
              <span class="title-text">套系详情</span>
              <span class="title-vice">本套系可拍摄以下产品内容：</span>
            </div>
            <div class="product-list">
              <el-descriptions :colon="false" class="product-list-item" :column="1" v-for="(item, i) in detailsInfo.productStatList" :key="i">
                <template slot="title">
                  <!-- :title="item.productName"  -->
                  <div>{{ item.productName }}</div>
                  <div class="type">服务类型：{{ item.serviceType == 1 ? '到店拍摄' : '上门拍摄' }}</div>
                </template>
                <el-descriptions-item v-for="(itemJ, j) in item.relationProductList" :key="j">
                  <template slot="label">
                    <div class="flex" v-if="!itemJ.mountList || itemJ.mountList.length == 0">
                      <span>{{ itemJ.relationProductName }}</span>
                      <!-- <span style="color: #999">
                        {{ itemJ.number + itemJ.priceUnit }}
                      </span> -->
                      <div style="color: #999" v-if="!itemJ.mountList">{{ itemJ.number + itemJ.priceUnit }}</div>
                    </div>
                    <div v-if="itemJ.mountList && itemJ.mountList.length > 0" style="flex: 1">
                      <div class="flex" style="padding-bottom: 8px" v-for="(n, i) in itemJ.mountList" :key="i">
                        <span class="subItem">
                          {{ itemJ.relationProductName }}
                          <span style="color: #999" v-if="itemJ.mountList && itemJ.mountList.length > 0">
                            {{ n.number + itemJ.priceUnit }}
                          </span>
                        </span>

                        <span class="subItem" style="padding-left: 40px">
                          <span>
                            <i style="padding-right: 20px; font-size: 16px; font-weight: bold" class="el-icon-connection"></i>
                            <span>{{ n.relationProductName }}</span>
                          </span>
                          <span style="padding-left: 10px; color: #999">
                            {{ n.number + n.priceUnit }}
                          </span>
                        </span>
                      </div>
                    </div>
                  </template>
                </el-descriptions-item>
              </el-descriptions>
            </div>
            <div class="title">
              <span class="title-mark"></span>
              <span class="title-text">图文详情</span>
            </div>
            <div class="graphic">
              <div class="graphic-item" v-for="(item, i) in detailsInfo.productInfo.detailList" :key="i">
                <el-image class="graphic-item-img" :src="item"></el-image>
                <!-- <div class="graphic-item-name">东方霓裳</div>
                <div class="graphic-item-tags">
                  <span>中国风</span>
                </div> -->
              </div>
            </div>
            <div class="title" ref="serverInstruction">
              <span class="title-mark"></span>
              <span class="title-text">服务说明</span>
            </div>
            <!-- <div class="highlights">
              <div class="highlights-title">亮点</div>
              <div class="highlights-content" v-if="detailsInfo.productInfo.brightPointNames">
                <div v-for="(item, i) in detailsInfo.productInfo.brightPointNames" :key="i">{{ item }}</div>
              </div>
            </div> -->
            <!-- <div class="line"></div> -->
            <div class="feature">
              <!-- <div class="feature-highlights">
                <div class="feature-highlights-title">拍摄特色</div>
              </div> -->
              <el-descriptions :column="1" :labelStyle="{ minWidth: '80px', color: '#000', fontWeight: 'bold', fontSize: '14px' }" :contentStyle="{ color: '#363f47' }">
                <el-descriptions-item label="服务说明">{{ detailsInfo.productInfo.serviceDescription }}</el-descriptions-item>
                <el-descriptions-item label="预约须知">{{ detailsInfo.productInfo.appointmentNotice }}</el-descriptions-item>
                <el-descriptions-item label="售后说明">{{ detailsInfo.productInfo.afterThat }}</el-descriptions-item>
                <el-descriptions-item label="服务流程">
                  <div v-for="(item, i) in detailsInfo.productInfo.serviceFlowList" :key="i">
                    <el-image class="server-item-img" :src="item"></el-image>
                  </div>
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane label="服务说明" name="1">服务说明</el-tab-pane>
      </el-tabs>
    </div>
  </el-dialog>
</template>

<script>
import { getProductDetail } from '@/api/store';
export default {
  props: {
    popupsTitle: {
      type: String,
      default: '',
    },
    showState: {
      type: Boolean,
      default: false,
    },
    currentItemChild: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      boxLoading: false,
      activeName: '0',
      detailsInfo: {
        productInfo: {},
      },
    };
  },
  watch: {
    showState(val) {
      if (val) {
        this.getData();
      }
    },
  },
  methods: {
    filterMarketPrice(num) {
      let flag = false;
      if (!!num && Number(num) > 0) flag = true;
      return flag;
    },
    async getData() {
      try {
        this.boxLoading = true;

        let responseDetail = (await getProductDetail({ data: { productId: parseInt(this.currentItemChild.productId) } })).data || { productInfo: {} };

        if (!responseDetail.productInfo) {
          responseDetail.productInfo = {};
        }
        this.detailsInfo = responseDetail;

        this.boxLoading = false;
      } catch (error) {
        this.boxLoading = false;
      }
    },
    handleMove(activeName) {
      if (activeName == 1) {
        this.$refs.elScrollbar.wrap.scrollTop = this.$refs.serverInstruction.offsetTop;
        return false;
      }
    },
    hidePopups() {
      this.$emit('hidePopups');
      this.$refs.elScrollbar.wrap.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.product-detail-container {
  .title {
    padding: 12px 0;
    border-bottom: 1px solid #999;
    .title-vice {
      margin-left: 20px;
      font-size: 14px;
    }
  }

  .product-info {
    align-items: center;
    // padding: 0 24px;
    .product-img {
      min-width: 100px;
      width: 100px;
      height: 100px;
      border-radius: 4px;
    }
    .product-r {
      margin-left: 20px;
      .product-title {
        font-size: 18px;
        font-weight: 600;
        color: #111;
      }
      .product-price {
        margin: 24px 0 20px 0;
        color: #f95051;
        font-weight: 600;
        font-size: 16px;
        .old-price {
          position: relative;
          margin-left: 6px;
          color: #999;
          font-weight: 400;
          font-size: 12px;
          .hr {
            position: absolute;
            top: 8px;
            left: 2px;
            width: 100%;
            height: 2px;
            background-color: #999;
          }
        }
      }
      .product-prompt {
        color: #999;
      }
    }
  }
  .city {
    align-content: center;
    margin: 24px 0;
    // padding: 0 24px;
    font-size: 14px;
    .city-label {
      min-width: 72px;
      color: #000;
    }
    .city-content {
      color: #666;
    }
  }
  .title-bg {
    padding: 16px;
    background-color: #eee;
    .main-title {
      font-size: 16px;
      font-weight: bold;
      color: #000;
    }
    .vice-title {
      margin-left: 20px;
    }
  }
  .product-list {
    margin: 12px 0;
    padding: 24px 32px;
    background-color: rgb(247, 247, 247);

    ::v-deep.el-descriptions-item__container {
      justify-content: space-between;
      padding-right: 200px;
      .el-descriptions-item__label {
        color: #333;
        flex: 1;
        display: flex;
        justify-content: space-between;
        padding-right: 40px;
        .flex {
          flex: 1;
          justify-content: space-between;
        }
        .subItem {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
      .el-descriptions-item__content {
        display: none;
      }
    }
    .product-list-item {
      margin-bottom: 16px;
      border-bottom: 1px solid #eee;
    }
    .product-list-item:last-child {
      border: none;
    }
    .type {
      font-weight: normal;
      font-size: 14px;
      padding-top: 10px;
    }
    ::v-deep .el-descriptions__body {
      background-color: rgb(247, 247, 247);
      margin-left: 40px;
    }
  }
  .graphic {
    // flex-wrap: wrap;
    // justify-content: space-between;
    // margin-top: 24px;
    margin: 12px 0;
    // &::after {
    //   content: '';
    //   width: 33%;
    //   height: 350px;
    // }
    .graphic-item {
      width: 100%;
      .graphic-item-img {
        width: 512px;
        // height: 350px;
        border-radius: 4px;
      }

      .graphic-item-name {
        margin: 0 4px;
        padding-top: 6px;
        font-size: 16px;
        color: #222;
      }
      .graphic-item-tags {
        margin-bottom: 20px;
        padding: 12px 4px;
        span {
          padding: 2px 12px;
          color: red;
          font-size: 14px;
          border: 1px solid red;
          border-radius: 4px;
        }
      }
    }
  }
  .line {
    margin: 12px 0;
    height: 1px;
    background-color: #eee;
  }
  .highlights {
    margin-top: 24px;
    padding: 0 24px;

    .highlights-title {
      font-weight: bold;
      font-size: 16px;
      color: #303133;
    }
    .highlights-content {
      margin-top: 12px;
      font-size: 14px;
      color: #222;
    }
  }
  .feature {
    margin-top: 8px;
    padding: 0 24px;
    .feature-highlights {
      margin-bottom: 12px;
      .feature-highlights-title {
        font-weight: bold;
        font-size: 16px;
        color: #303133;
      }
    }
    .server-item-img {
      width: 100px;
      height: 100px;
    }
  }
}
</style>
