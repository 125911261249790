<template>
  <div class="add-serve-product-container">
    <el-dialog :title="popupsTitle" :visible="showState" :close-on-click-modal="false" width="1000px" top="5vh" @close="hidePopups()">
      <div class="topSearch">
        <el-input style="width: 200px; padding-right: 10px" v-model="form.key" placeholder="请输入产品ID/产品名称"></el-input>
        <el-button icon="el-icon-search" type="primary" @click="handleSerch">查询</el-button>
        <el-button icon="el-icon-refresh-left" @click="handleReset">重置</el-button>
      </div>

      <ul class="product-list" v-loading="boxLoading">
        <el-scrollbar style="height: 100%">
          <li class="flex list-item" v-for="(item, i) in list" :key="i">
            <div class="flex item-l">
              <el-image class="product-img" :src="showUrl(item.coverPictureUrl, `x-oss-process=image/resize,m_fixed,h_${imageSize},w_${imageSize}`)">
                <span class="el-image-error" slot="error">
                  <el-image class="product-img" :src="item.coverPictureUrl">
                    <span class="el-image-error" slot="error">暂无图片</span>
                  </el-image>
                </span>
              </el-image>
              <div class="product-info">
                <div class="product-info-col">
                  <span class="label"></span>
                  <el-link type="primary" @click="handleProductDetail(item)">{{ item.productName + ' ( ID:' + item.productCode + ' )' }}</el-link>
                </div>
                <div class="product-info-col">
                  <span class="label"></span>
                  <span class="text">{{ '¥' + item.salePrice }}</span>
                  <span v-if="filterMarketPrice(item.marketPrice)" class="text disableText">
                    {{ '¥' + item.marketPrice }}
                    <span class="line"></span>
                  </span>
                </div>
                <div class="product-info-col">
                  <span class="label">产品类型：</span>
                  <span class="text">{{ item.categoryName }}</span>
                </div>
                <div class="product-info-col">
                  <span class="label">产品等级：</span>
                  <span class="text">{{ item.productLevelName }}</span>
                </div>
                <div class="product-info-col">
                  <span class="label">适用城市：</span>
                  <span class="text text-city">
                    <el-tooltip effect="dark" :content="item.productCityNames" placement="top" v-if="item.productCityNames.length > 50">
                      <span>{{ item.productCityNames }}</span>
                    </el-tooltip>
                    <span v-else>{{ item.productCityNames }}</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="item-r" :class="item.check ? 'item-r-disable' : 'item-r-checked'" @click="handleChecked(item)">{{ item.check ? '已添加' : '添加' }}</div>
          </li>
        </el-scrollbar>
      </ul>
    </el-dialog>
    <ProductDetailPopups popupsTitle="产品详情" :showState="isProductDetail" :currentItemChild="currentItemChild" v-on:hidePopups="isProductDetail = false" />
  </div>
</template>
<script>
import ProductDetailPopups from './productDetail';
import { getProductList, addServerProdcut } from '@/api/store';
export default {
  components: {
    ProductDetailPopups,
  },
  props: {
    popupsTitle: {
      type: String,
      default: '',
    },
    showState: {
      type: Boolean,
      default: false,
    },
    currentItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      imageSize: 200,
      boxLoading: false,
      btnLoading: false,
      form: {
        key: undefined,
      },
      list: [],
      isProductDetail: false,
      currentItemChild: {},
    };
  },
  watch: {
    showState(val) {
      if (val) {
        this.getData();
      }
    },
  },
  methods: {
    formateAliOOSUrl(oosUrl, option = '') {
      const urlArr = oosUrl.split('?');
      const baseUrl = urlArr[0];
      const url = baseUrl + '?' + option;
      return url;
    },
    showUrl(url, optString) {
      if (url) {
        const baseUrl = this.formateAliOOSUrl(url, optString);
        return baseUrl;
      }
      return '';
    },
    filterMarketPrice(num) {
      let flag = false;
      if (!!num && Number(num) > 0) flag = true;
      return flag;
    },
    async getData() {
      try {
        this.boxLoading = true;

        this.list =
          (
            await getProductList({
              data: {
                storeId: parseInt(this.currentItem.storeId),
                cityId: parseInt(this.currentItem.cityId),
                key: this.form.key,
                isInquire: 1,
              },
            })
          ).data || [];

        this.boxLoading = false;
      } catch (error) {
        this.boxLoading = false;
      }
    },
    handleSerch() {
      this.getData();
    },
    handleReset() {
      Object.assign(this.$data.form, this.$options.data().form);
      this.getData();
    },
    handleProductDetail(item) {
      this.currentItemChild = item;
      this.isProductDetail = true;
    },
    async handleChecked(item) {
      if (!item.check) {
        await addServerProdcut({ data: { storeId: parseInt(this.currentItem.storeId), productId: parseInt(item.productId) } });
        this.$notify.success({ title: '成功', message: '添加成功', duration: 2000 });
      } else {
        return;
      }
      //  else if (item.check) {
      //   await deleteServerProdcut({ data: { storeId: parseInt(this.currentItem.storeId), productId: parseInt(item.productId) } });
      //   this.$notify.success({ title: '成功', message: '取消成功', duration: 2000 });
      // }
      this.getData();
      this.$parent.getTableList();
    },
    hidePopups() {
      this.$emit('hidePopups');
    },
  },
};
</script>
<style lang="scss" scoped>
.add-serve-product-container {
  .topSearch {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }
  .serach-form-content {
    justify-content: center;
    margin-bottom: 24px;
    border-bottom: 1px solid #eee;
  }
  .product-list {
    height: 600px;
    .list-item {
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      .item-l {
        .product-img {
          min-width: 140px;
          width: 140px;
          height: 120px;
          border-radius: 12px;
        }
        .product-info {
          margin: 4px 0 0 10px;
          .product-info-col {
            margin-bottom: 8px;
            .label {
              font-weight: 550;
              color: #000;
              vertical-align: top;
            }
            .text {
              margin-right: 8px;
              font-size: 14px;
              color: #222;
              vertical-align: top;
            }
            .text-city {
              display: inline-block;
              width: 600px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .disableText {
              position: relative;
              color: #999;
              .line {
                position: absolute;
                top: 45%;
                left: 2px;
                width: 100%;
                height: 2px;
                background-color: #999;
              }
            }
          }
          .product-info-col:last-child {
            margin: 0;
          }
        }
      }
      .item-r {
        width: 80px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 6px;
        color: #fff;
      }
      .item-r:hover {
        cursor: pointer;
        opacity: 0.8;
      }
      .item-r-checked {
        background-color: rgba(236, 128, 141, 1);
      }
      .item-r-disable {
        background-color: rgba(215, 215, 215, 1);
      }
    }
  }
}
</style>
