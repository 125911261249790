<template>
  <div class="site-product-container">
    <div class="search-form-container">
      <el-form ref="searchForm" inline label-width="100px">
        <el-form-item label="供应商ID:">{{ storeDetailObj.storeId }}</el-form-item>
        <el-form-item label="供应商名称:">{{ storeDetailObj.storeName }}</el-form-item>
        <el-form-item label="已添加套系产品数量:" label-width="150px">
          <span style="color: #2861e2">{{ tableData.length }}</span>
          个
        </el-form-item>
      </el-form>

      <el-button type="primary" @click="seeShedSpaceCount()">棚位概况</el-button>
    </div>
    <div class="hr"></div>
    <div class="operate-container flex product-site-operate">
      <div class="title">
        <span class="title-mark"></span>
        <span class="title-text">服务产品设置</span>
      </div>
    </div>
    <div class="product">
      <el-form ref="searchForm" :model="tableQueryData" inline label-width="100px">
        <el-form-item label="">
          <el-select v-model="region" placeholder="请选择" @change="mirType">
            <el-option label="套系产品名称" value="0"></el-option>
            <el-option label="套系产品ID" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-input v-model="tableQueryData.data.key" placeholder="请输入套系关键字查询"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSerch">查询</el-button>
          <el-button icon="el-icon-refresh-left" @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" icon="el-icon-plus" @click="handleAddProduct()">添加服务产品</el-button>
      </div>
    </div>
    <div style="padding: 0 10px">
      <LTable :tableLoading="tableLoading" :tableData="tableData" :tableHeader="tableHeader" :tableQueryData="tableQueryData" :getTableList="getTableList">
        <template slot="salePrice" slot-scope="scope">
          <span>￥{{ scope.row.salePrice }}</span>
        </template>
        <template slot="marketPrice" slot-scope="scope">
          <span>￥{{ scope.row.marketPrice }}</span>
        </template>
        <template slot="isOnShelf" slot-scope="scope">
          <span :class="scope.row.isOnShelf === 1 ? 'state-success' : ''">{{ scope.row.isOnShelfName }}</span>
        </template>
        <template slot="operate" slot-scope="scope">
          <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </LTable>
    </div>

    <AddServeProductPopups :popupsTitle="popupsTitle" :showState="isAddServeProduct" :currentItem="currentItem" v-on:hidePopups="isAddServeProduct = false" />
    <el-dialog title="棚位概况" :visible.sync="shedSpaceCountShow" width="30%">
      <el-table :data="surveyData" border>
        <el-table-column property="productName" label="棚位类型"></el-table-column>
        <el-table-column property="number" label="棚位数量"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import AddServeProductPopups from './components/addServeProduct';
import { getStoreDetail, getStoreProductList, deleteServerProdcut } from '@/api/store';
import { shedOverview } from '@/api/shed-space.js';

export default {
  components: {
    AddServeProductPopups,
  },
  data() {
    return {
      id: undefined,
      storeDetailObj: {},
      region: undefined,
      tableLoading: false,
      tableHeader: [
        { label: '套系产品ID', prop: 'productCode', width: '100', align: 'center' },
        { label: '套系产品名称', prop: 'productName' },
        { label: '售价', prop: 'salePrice', align: 'center', render: true },
        { label: '市场价', prop: 'marketPrice', align: 'center', render: true },
        { label: '上架状态', prop: 'isOnShelf', align: 'center', width: '100', render: true },
        { label: '上/下架时间', prop: 'shelfTime', align: 'center', width: '160' },
        { label: '操作', prop: 'operate', align: 'center', width: '160', render: true },
      ],
      tableQueryData: {
        data: {
          storeId: undefined,
          storeName: undefined,
          key: undefined,
        },
      },
      tableData: [], //供应商分页列表
      popupsTitle: '',
      isAddServeProduct: false,
      currentItem: {},
      shedSpaceCountShow: false,
      surveyData: [],
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getData();
    this.getTableList();
    this.getShedOverview();
  },
  methods: {
    getShedOverview() {
      shedOverview({
        data: {
          storeId: this.id,
        },
      }).then((res) => {
        console.log(res);
        this.surveyData = res.data;
      });
    },
    async getData() {
      try {
        let responseDetail = (await getStoreDetail({ data: { storeId: [this.id] } })).data || [];
        this.storeDetailObj = responseDetail[0];
      } catch (error) {
        console.log(error);
      }
    },
    async getTableList() {
      try {
        this.tableLoading = true;
        this.tableQueryData.data.storeId = parseInt(this.id);

        this.tableData = (await getStoreProductList(this.tableQueryData)).data.productList || [];

        this.tableLoading = false;
      } catch (error) {
        this.tableLoading = true;
      }
    },
    /**
     * 打开棚位数量弹窗
     */
    seeShedSpaceCount() {
      this.shedSpaceCountShow = true;
    },
    mirType(value) {
      console.log(value);
    },
    handleSerch() {
      this.getTableList();
    },
    handleReset() {
      Object.assign(this.$data.tableQueryData, this.$options.data().tableQueryData);
      this.getTableList();
    },
    handleAddProduct() {
      this.popupsTitle = `添加服务产品 ( 当前产品适用范围为：${this.storeDetailObj.cityName}， 如需选择其他城市产品，请先修改供应商位置坐标)`;
      this.currentItem = this.storeDetailObj;
      this.isAddServeProduct = true;
    },
    async handleDelete(row) {
      this.$confirm('是否确认删除当前产品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await deleteServerProdcut({ data: { storeId: parseInt(this.id), productId: parseInt(row.productId) } });
          this.$notify.success({ title: '成功', message: '删除成功', duration: 2000 });
          this.getTableList();
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.site-product-container {
  background-color: #fff;
  .product-site-operate {
    justify-content: space-between;
  }
  .operate-container {
    padding: 11px 6px;
  }
  .hr {
    height: 16px;
    background-color: #f7f7f7;
  }
}
.search-form-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px !important;
  box-sizing: border-box;
  ::v-deep .el-form-item {
    margin-bottom: 0 !important;
  }
}
.product {
  display: flex;
  padding: 0 12px;
  box-sizing: border-box;
}
.table-tag {
  padding: 0 10px;
}
</style>
